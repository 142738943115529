/* src/styles.css */
body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
}

.food-card {
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 20px;
  transition: transform 0.2s;
  width: 100%;
  height: 400px;
}

.food-card img {
  width: 100%;
  height: 70%;
  object-fit: cover;
}

.food-card h2 {
  margin: 0;
  padding: 15px;
  font-size: 24px;
  background-color: #ff6b6b;
  color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.match {
  background-color: #ff6b6b;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.match img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .food-card {
    max-width: 100%;
    height: auto;
  }

  .food-card img {
    height: auto;
  }

  .food-card h2 {
    font-size: 20px;
  }
}
